<template>
  <div class="page-container">
    <div class="content-wrap">
      <header class="">
        <div class="container">
          <nav class="d-flex justify-content-between">
            <div class="brand pt-3 pb-4">
              <router-link to="/">
                <img src="./assets/images/logo/temco_logo_hvit.svg">
              </router-link>
            </div>
            <ul class="nav">
            <!--
              <li>
                <router-link to="/support" :class="{'router-link-active': subIsActive('/support')}">
                  Hjelp &amp; Support
                </router-link>
              </li>
              -->
            </ul>
          </nav>
        </div>
      </header>
      <main class="container py-5">
        <router-view/>
      </main>
    </div>
    <footer class="footer text-center py-5 mt-5">
      <p><img class="footer-image" src="./assets/images/logo/temco_logo_hvit.svg"></p>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    }
  },
  computed: {
    year(){
      let date = new Date();
      return date.getFullYear();
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/fonts.scss';
@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>
