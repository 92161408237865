import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Support.vue')
  },
  /*{
    path: '/support',
    name: 'Support',
    component: () => import('../views/Support.vue'),
    meta: {
      title: "Support"
    }
  },*/
  {
    path: '/support/sjekkliste_ved_stopp',
    name: 'SupportChecklist',
    component: () => import('../views/Support/Checklist.vue'),
    meta: {
      title: "Sjekkliste ved stopp"
    }
  },
  /*{
    path: '/kontakt',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: "Kontakt oss"
    }
  },*/
  { path: '/404', component: () => import('../views/404.vue') },  
  { path: '/:pathMatch(.*)*', redirect: '/404' },  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
  if(nearestWithTitle){
    document.title = nearestWithTitle.meta.title + ' - Temco Vareheiser';
  } else {
    document.title = "Temco Vareheiser";
  }

  next();
});

export default router
